/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import Header from "./header"
import "./layout.css"

const Section = styled.div`
  min-height: 60vh;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Footer = styled.footer`
  height: 100px;
  border-top: solid 2px rgb(197, 158, 93);
  background-color: rgb(18, 18, 18);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  > div {
    min-height: 100px;
    width: 80%;
    margin: auto;
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 0.5em;
      width: 70vw;
      text-align: center;
    }
    @media (min-width: 769px) and (max-width: 1200px) {
      flex-direction: row;
      font-size: 0.6em;
      width: 80%;
    }
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <meta name="format-detection" content="telephone=no" />
          <title>AutoSerwis</title>
    </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Section>{children}</Section>
      <Footer>
        <div>
          <div>Auto Serwis</div>
          <div>Serwis Samochodowy</div>
          <div>NIP 521 265 68 54</div>
          <div>ul. Słoneczna 39</div>
          <div>05-500 Stara Iwiczna</div>
        </div>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
