import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  background-color: whitesmoke;
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  transform: translate(50%,-50%);
  background-color: rgb(197, 158, 93);
  border-radius: 10px;
  box-shadow: -1px 3px 3px 0px rgba(197, 158, 1, 0.6);
  @media (max-width: 600px){
    width: 70%;
    transform: translate(0%,-50%);
    margin: auto;
  }
`
const NavItem = styled.div`
    a{
        text-decoration: none;
        color: black;
        cursor: pointer;
        @media (max-width: 600px){
        font-size: 0.6em;
    };
    }
`

const Navigation = () => (
  <Container>
        <NavItem><Link to="/offer/">Zobacz ofertę</Link></NavItem>
        <NavItem><Link to="/">O NAS</Link></NavItem>
        <NavItem><Link to="/contact">Kontakt</Link></NavItem>
  </Container>
)

export default Navigation
