
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import logo from "../images/autoserwislogo.png"
import Navigation from "../components/navigation"

const Container = styled.div`
  height: 15vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: solid 2px rgb(197, 158, 93);
  z-index: 100;
  background-color: rgb(18, 18, 18);
`

const H1 = styled.h1`
  vertical-align: middle;
  padding-top: 10px;
  width: 50vw;
  text-align: center;
  color: rgb(197, 158, 93);
  @media (max-width: 600px) {
    font-size: 1.3em;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 50vw;
  img {
    width: 20%;
    @media (max-width: 700px) {
      width: 50%;
    }
  }
`

const Header = ({ siteTitle }) => (
  <>
    <Container>
      <H1>AutoSerwis</H1>
      <Logo>
        <img src={logo} alt="Logo"></img>
      </Logo>
    </Container>
    <Navigation></Navigation>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
